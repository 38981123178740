<template style="background-color: #ebedf0">
  <van-nav-bar title="登陆">

  </van-nav-bar>


<div style="text-align: center">
  <van-image width="13rem" height="13rem" :src="logoImg"/>
</div>
  <van-form @submit="onSubmit" style="margin-top: 1rem">
    <van-cell-group inset>
      <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          autocomplete="off"
          :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          autocomplete="off"
          :rules="[{ required: true, message: '请填写密码' }]"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button block type="primary" native-type="submit" loading-type="spinner" :loading="loading">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import {login} from '@/http/user'
import {saveAuth} from '@/services/auth'
// import {Toast} from 'vant'
import {Notify} from 'vant'
import logoImg from "../assets/video.png"


export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      logoImg: logoImg
    }
  },
  methods: {
    onSubmit() {
      this.loading = !this.loading
      login(this.username, this.password).then(function (response) {
        if (response.data.token) {
          let token = response.data.token
          let user = response.data.user
          //写入鉴权
          saveAuth(token)
          localStorage.setItem('username',user.name)
          //跳转页面
          Notify({ type: 'success', message: response.msg });
          this.$router.push('/')
        } else {
          Notify({ type: 'danger', message: response.msg });
        }
        this.loading = !this.loading
      }.bind(this))
    }
  }
}
</script>

<style scoped>

</style>